<div class="flex h-full flex-col">
  <div class="flex h-[56px] flex-shrink-0 items-center gap-[8px] px-[16px]">
    <ng-container *ngIf="checkCount === 0">
      <div
        class="flex h-[28px] cursor-pointer select-none items-center gap-[8px] rounded-m px-[12px] py-[4px] hover:bg-lightblue"
        (click)="setTab(0)"
        [class.bg-lightblue]="currentTab === 0"
      >
        <div
          class="text-body-s"
          [class.text-blue]="currentTab === 0"
          [class.text-neutral40]="currentTab !== 0"
        >
          전체
        </div>
        <div
          class="text-label-m"
          [class.text-blue]="currentTab === 0"
          [class.text-black]="currentTab !== 0"
        >
          {{ collectionSize }}
        </div>
      </div>
      <div
        class="flex h-[28px] cursor-pointer select-none items-center gap-[8px] rounded-m px-[12px] py-[4px] hover:bg-lightblue"
        *ngFor="let tab of tabs; let idx = index"
        (click)="setTab(idx + 1)"
        [class.bg-lightblue]="currentTab === idx + 1"
      >
        <div
          class="text-body-s"
          [class.text-blue]="currentTab === idx + 1"
          [class.text-neutral40]="currentTab !== idx + 1"
        >
          {{ tab }}
        </div>
        <div
          class="text-label-m"
          [class.text-blue]="currentTab === idx + 1"
          [class.text-black]="currentTab !== idx + 1"
        >
          {{ tabCounts[idx] }}
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="checkCount > 0">
      <div
        class="flex h-[28px] cursor-pointer select-none items-center gap-[8px] rounded-m bg-lightred px-[12px] py-[4px]"
      >
        <div class="text-body-s text-red">선택</div>
        <div class="text-label-m text-red">
          {{ checkCount }}
        </div>
      </div>
    </ng-container>

    <div class="flex-auto"></div>
    <div
      [innerHTML]="sanitizer.bypassSecurityTrustHtml(defaultButton)"
      *ngIf="defaultButton !== '' && checkCount === 0"
      (click)="event.emit({ id: 'table', data: 'default' })"
    ></div>
    <div
      [innerHTML]="sanitizer.bypassSecurityTrustHtml(deleteButton)"
      *ngIf="deleteButton !== '' && checkCount > 0"
      (click)="event.emit({ id: 'table', data: 'delete' })"
    ></div>
  </div>
  <div
    class="scrollbar relative mx-[16px] flex-auto overflow-auto text-body-m text-neutral0"
  >
    <table class="w-full table-fixed text-left text-body-m text-neutral0">
      <thead class="h-[32px]">
        <tr>
          <th
            class="border-b border-neutral80 bg-neutral90 ps-[8px] text-label-s text-neutral40"
            [style.width]="column.width ? column.width + 'px' : 'auto'"
            *ngFor="let column of columns; let idx = index"
          >
            <div class="flex select-none items-center">
              {{ column.name }}
              <div
                *ngIf="idx === 0"
                class="h-[18px] w-[18px] cursor-pointer"
                (click)="toggleCheckAll()"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  *ngIf="checkAll"
                >
                  <path
                    d="M0 4C0 1.79086 1.79086 0 4 0H14C16.2091 0 18 1.79086 18 4V14C18 16.2091 16.2091 18 14 18H4C1.79086 18 0 16.2091 0 14V4Z"
                    fill="#34C759"
                  />
                  <path
                    d="M0.75 4C0.75 2.20507 2.20507 0.75 4 0.75H14C15.7949 0.75 17.25 2.20507 17.25 4V14C17.25 15.7949 15.7949 17.25 14 17.25H4C2.20507 17.25 0.75 15.7949 0.75 14V4Z"
                    stroke="black"
                    stroke-opacity="0.1"
                    stroke-width="1.5"
                  />
                  <path
                    d="M8.69852 13.7452C8.47205 14.1011 8.1593 14.2736 7.79264 14.2736C7.42597 14.2736 7.12401 14.1227 6.84362 13.7452L4.13675 10.4236C3.97499 10.208 3.87793 9.9707 3.87793 9.72266C3.87793 9.23736 4.25538 8.83834 4.74067 8.83834C5.05342 8.83834 5.29067 8.9354 5.56028 9.29128L7.7495 12.1168L12.3544 4.71873C12.5593 4.39521 12.8397 4.22266 13.1201 4.22266C13.5946 4.22266 14.0368 4.54619 14.0368 5.05305C14.0368 5.2903 13.8966 5.53834 13.7671 5.76481L8.69852 13.7452Z"
                    fill="white"
                  />
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  *ngIf="!checkAll"
                >
                  <path
                    d="M0.75 4C0.75 2.20507 2.20507 0.75 4 0.75H14C15.7949 0.75 17.25 2.20507 17.25 4V14C17.25 15.7949 15.7949 17.25 14 17.25H4C2.20507 17.25 0.75 15.7949 0.75 14V4Z"
                    fill="white"
                    stroke="#B6BCC9"
                    stroke-width="1.5"
                  />
                </svg>
              </div>
              <div
                class="mx-[4px] h-[18px] w-[18px] cursor-pointer"
                (click)="sortColumn(column)"
                *ngIf="column.sortable && sortTarget !== column"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.0547 14.2188C12.7324 14.5586 12.2168 14.5527 11.9004 14.2188L8.95312 11.1895C8.80664 11.043 8.71289 10.8203 8.71289 10.6211C8.71289 10.1699 9.03516 9.84766 9.48633 9.84766C9.69727 9.84766 9.88477 9.91797 10.0312 10.0703L11.2207 11.3066L11.7246 11.8984L11.6953 10.8203V3.85938C11.6953 3.4082 12.0293 3.07422 12.4746 3.07422C12.9258 3.07422 13.2598 3.4082 13.2598 3.85938V10.8203L13.2305 11.8984L13.7344 11.3066L14.9238 10.0703C15.0703 9.91797 15.252 9.84766 15.4688 9.84766C15.9199 9.84766 16.2422 10.1699 16.2422 10.6211C16.2422 10.8203 16.1484 11.043 16.002 11.1895L13.0547 14.2188ZM4.94531 3.33203C5.26758 2.99219 5.77734 2.99219 6.09961 3.33203L9.04688 6.35547C9.19336 6.50195 9.28711 6.71875 9.28711 6.91797C9.28711 7.375 8.96484 7.69727 8.51367 7.69727C8.29688 7.69727 8.11523 7.62109 7.96875 7.47461L6.7793 6.23242L6.27539 5.64648L6.30469 6.72461V13.6855C6.30469 14.1367 5.9707 14.4707 5.52539 14.4707C5.07422 14.4707 4.74023 14.1367 4.74023 13.6855V6.72461L4.76953 5.64648L4.26562 6.23242L3.07617 7.47461C2.92969 7.62109 2.74805 7.69727 2.53125 7.69727C2.08008 7.69727 1.75781 7.375 1.75781 6.91797C1.75781 6.71875 1.85156 6.50195 1.99805 6.35547L4.94531 3.33203Z"
                    fill="#B6BCC9"
                  />
                </svg>
              </div>
              <div
                class="mx-[4px] h-[18px] w-[18px] cursor-pointer"
                (click)="sortColumn(column)"
                *ngIf="sortTarget === column && !sortOrder"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="width: 16px; color: #b6bcc9"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="48"
                    d="M112 244l144-144 144 144M256 120v292"
                  />
                </svg>
              </div>
              <div
                class="mx-[4px] h-[18px] w-[18px] cursor-pointer"
                (click)="sortColumn(column)"
                *ngIf="sortTarget === column && sortOrder"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="width: 16px; color: #b6bcc9"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="48"
                    d="M112 268l144 144 144-144M256 392V100"
                  />
                </svg>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        @for (
          data of displayDatas | slice: (page - 1) * pageSize : page * pageSize;
          track data
        ) {
          <tr
            class="h-[40px] border-b border-neutral80 hover:bg-neutral80"
            [class.bg-neutral80]="data.checked"
          >
            <td
              *ngFor="let column of columns"
              class="ps-[8px]"
              (click)="
                options.rowClick
                  ? column.click
                    ? undefined
                    : options.rowClick(data)
                  : undefined
              "
              [class.cursor-pointer]="options.rowClick && !column.click"
            >
              <ng-container *ngIf="!column.data">
                {{ data[column.id] }}
              </ng-container>

              <div
                class="float-left me-[12px] h-[24px] w-[24px]"
                *ngIf="column.profile"
              >
                <app-profile
                  [path]="data.profile"
                  [name]="data.name"
                ></app-profile>
              </div>
              <div
                *ngIf="column.data"
                [innerHTML]="
                  sanitizer.bypassSecurityTrustHtml(column.data(data))
                "
                (click)="
                  column.click ? clickColumn(column, data, $event) : undefined
                "
              ></div>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
  <div class="mx-[16px] flex h-[40px] flex-shrink-0 items-end">
    <div
      class="flex h-[28px] flex-auto select-none gap-[8px] text-label-m leading-none text-neutral0"
    >
      <div
        class="flex h-[28px] w-[28px] cursor-pointer items-center justify-center rounded-s hover:bg-neutral80"
        (click)="setPage(page - 1)"
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.2929 4.79297L11.7071 6.20718L8.4142 9.50008L11.7071 12.793L10.2929 14.2072L5.58577 9.50008L10.2929 4.79297Z"
            fill="#0B0C0F"
          />
        </svg>
      </div>
      <div
        class="flex h-[28px] w-[28px] cursor-pointer items-center justify-center rounded-s bg-neutral80 hover:bg-neutral80"
        *ngFor="let p of pages"
        [class.bg-neutral80]="p === page"
        (click)="setPage(p)"
      >
        {{ p }}
      </div>
      <div
        class="flex h-[28px] w-[28px] cursor-pointer items-center justify-center rounded-s hover:bg-neutral80"
        (click)="setPage(page + 1)"
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.70712 14.207L6.29291 12.7928L9.5858 9.49992L6.29291 6.20703L7.70712 4.79282L12.4142 9.49992L7.70712 14.207Z"
            fill="#0B0C0F"
          />
        </svg>
      </div>
    </div>
    <!-- <div
      class="flex h-[28px] w-[120px] cursor-pointer select-none items-center gap-[8px] rounded-s border border-neutral80 bg-neutral100 px-[8px]"
    >
      <div class="flex-auto text-body-m text-neutral0">{{ pageSize }}개 씩</div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.23331 12.4613C9.11324 12.6099 8.88676 12.6099 8.76668 12.4613L4.84828 7.61358C4.68971 7.41739 4.82934 7.12499 5.0816 7.12499L12.9184 7.12499C13.1707 7.12499 13.3103 7.41739 13.1517 7.61358L9.23331 12.4613Z"
          fill="#0B0C0F"
        />
      </svg>
    </div> -->
    <div class="relative h-[28px] w-[120px]">
      <select
        class="h-[28px] w-[120px] select-none appearance-none rounded-s border border-neutral80 bg-neutral100 px-[8px] text-body-m text-neutral0"
        [(ngModel)]="pageSize"
        (ngModelChange)="processData()"
      >
        <option [ngValue]="10">10개 씩</option>
        <option [ngValue]="20">20개 씩</option>
        <option [ngValue]="50">50개 씩</option>
        <option [ngValue]="100">100개 씩</option>
      </select>
      <svg
        class="absolute right-[8px] top-[5px]"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.23331 12.4613C9.11324 12.6099 8.88676 12.6099 8.76668 12.4613L4.84828 7.61358C4.68971 7.41739 4.82934 7.12499 5.0816 7.12499L12.9184 7.12499C13.1707 7.12499 13.3103 7.41739 13.1517 7.61358L9.23331 12.4613Z"
          fill="#0B0C0F"
        />
      </svg>
    </div>
  </div>
</div>

import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AjaxService } from '../../core/services/ajax.service';
import { ClientService } from '../../core/services/client.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrl: './password-change.component.scss',
})
export class PasswordChangeComponent {
  ajax = inject(AjaxService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  client = inject(ClientService);
  sanitizer = inject(DomSanitizer);

  password = '';
  password2 = '';

  auth = '';

  async ngOnInit() {
    const auth = this.route.snapshot.paramMap.get('auth');
    if (auth) {
      this.auth = auth;
      const r = await this.ajax.post('auth/checkAuth', {
        auth,
      });
      if (r.data.cnt > 0) {
        // ok
      } else {
        this.client.setToast({
          data: '유효하지 않은 인증 코드 입니다.',
        });
        this.router.navigate(['']);
      }
    } else {
      this.client.setToast({
        data: '유효하지 않은 인증 코드 입니다.',
      });
      this.router.navigate(['']);
    }
  }

  checkPassword() {
    const hasUpperCase = (str: string) => {
      return /[A-Z]/.test(str);
    };
    const hasLowerCase = (str: string) => {
      return /[a-z]/.test(str);
    };
    const hasNumber = (str: string) => {
      return /\d/.test(str);
    };
    const hasSpecialCharacter = (str: string) => {
      return /[!@#$%^&*(),.?":{}|<>]/.test(str);
    };

    let count = 0;
    if (hasUpperCase(this.password)) count += 1;
    if (hasLowerCase(this.password)) count += 1;
    if (hasNumber(this.password)) count += 1;
    if (hasSpecialCharacter(this.password)) count += 1;

    return count >= 2 && this.password.length >= 8;
  }

  passwordEnter() {
    (<HTMLInputElement>document.querySelector('.password2')).focus();
  }

  async submit() {
    if (this.password !== this.password2) {
      this.client.setToast({
        bg: 'red',
        data: '동일한 비밀번호를 입력해주세요.',
      });
      return;
    }

    if (!this.checkPassword()) {
      this.client.setToast({
        bg: 'red',
        data: '비밀번호가 유효하지 않습니다.',
      });
      return;
    }

    await this.ajax.post('auth/changePasswordAction', {
      auth: this.auth,
      password: this.password,
    });

    this.client.setToast({
      data: '비밀번호 변경에 성공하였습니다.',
    });
    this.router.navigate(['']);
  }
}

import { Component, EventEmitter, Input, inject } from '@angular/core';
import { ClientService } from '../../core/services/client.service';
import { AjaxService } from '../../core/services/ajax.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
})
export class SearchComponent {
  @Input() search = '';

  client = inject(ClientService);
  ajax = inject(AjaxService);
  router = inject(Router);
  sanitizer = inject(DomSanitizer);
  socket = inject(Socket);

  editMode = false;
  editInterview = -1;

  tableEvent = new EventEmitter();

  tabs = ['미정', '예정', '진행', '완료', '합격', '불합격'];

  columns: any = [
    {
      name: '',
      width: 36,
      data: (data: any) =>
        data.checked
          ? `<svg class="cursor-pointer" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 4C0 1.79086 1.79086 0 4 0H14C16.2091 0 18 1.79086 18 4V14C18 16.2091 16.2091 18 14 18H4C1.79086 18 0 16.2091 0 14V4Z" fill="#34C759"/>
      <path d="M0.75 4C0.75 2.20507 2.20507 0.75 4 0.75H14C15.7949 0.75 17.25 2.20507 17.25 4V14C17.25 15.7949 15.7949 17.25 14 17.25H4C2.20507 17.25 0.75 15.7949 0.75 14V4Z" stroke="black" stroke-opacity="0.1" stroke-width="1.5"/>
      <path d="M8.69852 13.7452C8.47205 14.1011 8.1593 14.2736 7.79264 14.2736C7.42597 14.2736 7.12401 14.1227 6.84362 13.7452L4.13675 10.4236C3.97499 10.208 3.87793 9.9707 3.87793 9.72266C3.87793 9.23736 4.25538 8.83834 4.74067 8.83834C5.05342 8.83834 5.29067 8.9354 5.56028 9.29128L7.7495 12.1168L12.3544 4.71873C12.5593 4.39521 12.8397 4.22266 13.1201 4.22266C13.5946 4.22266 14.0368 4.54619 14.0368 5.05305C14.0368 5.2903 13.8966 5.53834 13.7671 5.76481L8.69852 13.7452Z" fill="white"/>
      </svg>
      `
          : `<svg class="cursor-pointer" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.75 4C0.75 2.20507 2.20507 0.75 4 0.75H14C15.7949 0.75 17.25 2.20507 17.25 4V14C17.25 15.7949 15.7949 17.25 14 17.25H4C2.20507 17.25 0.75 15.7949 0.75 14V4Z" fill="white" stroke="#B6BCC9" stroke-width="1.5"/>
      </svg>
      `,
      click: async (data: any) => {
        data.checked = !data.checked;
        this.tableEvent.emit({
          name: 'check',
        });
      },
    },
    {
      name: '이름',
      id: 'name',
      sortable: true,
      width: 82,
    },
    {
      width: 112,
      name: '면접 상태',
      id: '',
      sortable: true,
      data: (data: any) => {
        if (data.types[0] === 0) {
          return `<div class="flex gap-[12px] items-center text-body-m text-neutral0">
          <div class="bg-neutral90 rounded-s w-[24px] h-[24px] flex items-center justify-center"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.48828 14.5352C3.41602 14.5352 2.71289 13.7266 2.71289 12.7598C2.71289 12.4609 2.78906 12.1562 2.95312 11.8691L7.46484 3.98242C7.80469 3.39648 8.39648 3.0918 9 3.0918C9.59766 3.0918 10.1836 3.39062 10.5293 3.98242L15.041 11.8633C15.2051 12.1504 15.2812 12.4609 15.2812 12.7598C15.2812 13.7266 14.5781 14.5352 13.5059 14.5352H4.48828ZM9.00586 10.3457C9.41016 10.3457 9.64453 10.1172 9.66797 9.69531L9.76172 7.16992C9.78516 6.73047 9.46289 6.42578 9 6.42578C8.53125 6.42578 8.21484 6.72461 8.23828 7.16992L8.33203 9.70117C8.34961 10.1172 8.58984 10.3457 9.00586 10.3457ZM9.00586 12.4609C9.46875 12.4609 9.83203 12.168 9.83203 11.7168C9.83203 11.2773 9.47461 10.9785 9.00586 10.9785C8.53711 10.9785 8.17383 11.2773 8.17383 11.7168C8.17383 12.168 8.53711 12.4609 9.00586 12.4609Z" fill="#363D49"/></svg></div>
          미정</div>`;
        } else if (data.types[0] === 1) {
          return `<div class="flex gap-[12px] items-center text-body-m text-neutral0">
          <div class="bg-blue rounded-s w-[24px] h-[24px] flex items-center justify-center"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="#007AFF"/><path d="M6.97266 7.54492C6.81445 7.54492 6.67969 7.43945 6.61523 7.29297C6.54492 7.12891 6.49805 6.90039 6.49805 6.63086C6.49805 5.6582 7.3125 4.89062 8.30859 4.89062C8.73047 4.89062 9.14062 5.01367 9.43359 5.24219C9.55078 5.33594 9.61523 5.45898 9.61523 5.59375C9.61523 5.70508 9.55664 5.80469 9.46289 5.875L7.21875 7.45117C7.125 7.51562 7.06055 7.54492 6.97266 7.54492ZM17.0273 7.54492C16.9336 7.54492 16.8691 7.51562 16.7812 7.45117L14.5371 5.875C14.4375 5.80469 14.3789 5.70508 14.3789 5.59375C14.3789 5.45898 14.4434 5.33594 14.5605 5.24219C14.8535 5.01367 15.2637 4.89062 15.6855 4.89062C16.6816 4.89062 17.4961 5.6582 17.4961 6.63086C17.4961 6.90039 17.4551 7.12891 17.3848 7.29297C17.3145 7.43945 17.1855 7.54492 17.0273 7.54492ZM6.96094 17.4648C6.72656 17.2422 6.72656 16.873 6.96094 16.6387L7.89258 15.707C6.89648 14.6758 6.28711 13.2754 6.28711 11.7344C6.28711 8.58203 8.8418 6.02148 12 6.02148C15.1523 6.02148 17.707 8.58203 17.707 11.7344C17.707 13.2754 17.0977 14.6816 16.1016 15.707L17.0332 16.6387C17.2676 16.873 17.2676 17.2422 17.0332 17.4648C16.793 17.7109 16.418 17.6934 16.1719 17.4531L15.1875 16.4746C14.2734 17.084 13.1777 17.4473 12 17.4473C10.8164 17.4473 9.7207 17.084 8.8125 16.4746L7.82812 17.4531C7.58203 17.6992 7.20117 17.7109 6.96094 17.4648ZM9.64453 12.584H11.9941C12.293 12.584 12.5332 12.3496 12.5332 12.0508V8.86914C12.5332 8.57031 12.293 8.33594 11.9941 8.33594C11.6953 8.33594 11.4609 8.57031 11.4609 8.86914V11.5176H9.64453C9.33984 11.5176 9.10547 11.752 9.10547 12.0508C9.10547 12.3496 9.33984 12.584 9.64453 12.584Z" fill="white"/></svg></div>
          예정</div>`;
        } else if (data.types[0] === 2) {
          return `<div class="flex gap-[12px] items-center text-body-m text-neutral0">
          <div class="bg-orange rounded-s w-[24px] h-[24px] flex items-center justify-center"><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 10.5C3.675 10.5 3 11.175 3 12C3 12.825 3.675 13.5 4.5 13.5C5.325 13.5 6 12.825 6 12C6 11.175 5.325 10.5 4.5 10.5ZM19.5 10.5C18.675 10.5 18 11.175 18 12C18 12.825 18.675 13.5 19.5 13.5C20.325 13.5 21 12.825 21 12C21 11.175 20.325 10.5 19.5 10.5ZM12 10.5C11.175 10.5 10.5 11.175 10.5 12C10.5 12.825 11.175 13.5 12 13.5C12.825 13.5 13.5 12.825 13.5 12C13.5 11.175 12.825 10.5 12 10.5Z" fill="white"/></svg></div>
          진행</div>`;
        } else if (data.types[0] === 3) {
          return `<div class="flex gap-[12px] items-center text-body-m text-neutral0">
          <div class="bg-neutral0 rounded-s w-[24px] h-[24px] flex items-center justify-center"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.6123 3.45898C15.3301 4.16309 15.501 5.11328 15.501 6.50098V11.6279C15.501 13.0088 15.3301 13.959 14.6123 14.6699C13.9014 15.3809 12.9375 15.5654 11.5635 15.5654H6.43652C5.0625 15.5654 4.09863 15.3809 3.3877 14.6699C2.66992 13.9658 2.49902 13.0088 2.49902 11.6279V6.49414C2.49902 5.12012 2.67676 4.16992 3.3877 3.45898C4.09863 2.74805 5.0625 2.56348 6.42969 2.56348H11.5635C12.9375 2.56348 13.9014 2.74805 14.6123 3.45898ZM8.81543 10.4043C9.2666 10.4043 9.58105 10.1719 9.63574 9.85742C9.64258 9.82324 9.64258 9.7959 9.64941 9.77539C9.7041 9.44727 9.99805 9.21484 10.4014 8.95508C11.1396 8.4834 11.5293 8.08008 11.5293 7.28711C11.5293 6.08398 10.4219 5.33203 9.04785 5.33203C7.81055 5.33203 6.93555 5.85156 6.66211 6.54883C6.61426 6.67871 6.58008 6.80859 6.58008 6.96582C6.58008 7.34863 6.8877 7.64258 7.27734 7.64258C7.57129 7.64258 7.79004 7.5332 7.9541 7.32129L8.03613 7.21191C8.26172 6.87012 8.54199 6.71973 8.88379 6.71973C9.33496 6.71973 9.66309 7.01367 9.66309 7.38965C9.66309 7.78613 9.3623 7.98438 8.78809 8.38086C8.28223 8.72949 7.93359 9.07812 7.93359 9.63867V9.69336C7.93359 10.165 8.24805 10.4043 8.81543 10.4043ZM8.80859 12.7148C9.35547 12.7148 9.77246 12.373 9.77246 11.8535C9.77246 11.334 9.35547 10.9785 8.80859 10.9785C8.26172 10.9785 7.83789 11.334 7.83789 11.8535C7.83789 12.373 8.26855 12.7148 8.80859 12.7148Z" fill="#FFCC00"/></svg></div>
          완료</div>`;
        } else if (data.types[0] === 4) {
          return `<div class="flex gap-[12px] items-center text-body-m text-neutral0">
          <div class="bg-green rounded-s w-[24px] h-[24px] flex items-center justify-center"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9 15C12.3137 15 15 12.3137 15 9C15 5.68629 12.3137 3 9 3C5.68629 3 3 5.68629 3 9C3 12.3137 5.68629 15 9 15ZM7.19531 8.1682C7.58203 8.1682 7.92188 7.8225 7.92188 7.34789C7.92188 6.86156 7.58203 6.51 7.19531 6.51C6.81445 6.51 6.49219 6.86156 6.49219 7.34789C6.49219 7.8225 6.81445 8.1682 7.19531 8.1682ZM10.7988 8.1682C11.1797 8.1682 11.5195 7.8225 11.5195 7.34789C11.5195 6.86156 11.1797 6.51 10.7988 6.51C10.4121 6.51 10.084 6.86156 10.084 7.34789C10.084 7.8225 10.4121 8.1682 10.7988 8.1682ZM9 11.7014C10.2891 11.7014 11.1562 10.8459 11.1562 10.4299C11.1562 10.2776 11.0039 10.2131 10.8633 10.2717L10.7981 10.3044L10.7981 10.3044C10.3567 10.5262 9.84853 10.7815 9 10.7815C8.11198 10.7815 7.59191 10.5113 7.14366 10.2784L7.13086 10.2717C6.99609 10.2073 6.84375 10.2776 6.84375 10.4299C6.84375 10.8459 7.70508 11.7014 9 11.7014Z" fill="white"/></svg></div>
          합격</div>`;
        } else if (data.types[0] === 5) {
          return `<div class="flex gap-[12px] items-center text-body-m text-neutral0">
          <div class="bg-red rounded-s w-[24px] h-[24px] flex items-center justify-center"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9 15C12.3137 15 15 12.3137 15 9C15 5.68629 12.3137 3 9 3C5.68629 3 3 5.68629 3 9C3 12.3137 5.68629 15 9 15ZM7.19531 8.1682C7.58203 8.1682 7.92188 7.8225 7.92188 7.34789C7.92188 6.86156 7.58203 6.51 7.19531 6.51C6.81445 6.51 6.49219 6.86156 6.49219 7.34789C6.49219 7.8225 6.81445 8.1682 7.19531 8.1682ZM10.7988 8.1682C11.1797 8.1682 11.5195 7.8225 11.5195 7.34789C11.5195 6.86156 11.1797 6.51 10.7988 6.51C10.4121 6.51 10.084 6.86156 10.084 7.34789C10.084 7.8225 10.4121 8.1682 10.7988 8.1682ZM9 10.2478C10.2891 10.2478 11.1562 11.1033 11.1562 11.5193C11.1562 11.6716 11.0039 11.7361 10.8633 11.6775L10.7981 11.6448C10.3567 11.423 9.84853 11.1677 9 11.1677C8.11198 11.1677 7.59191 11.438 7.14366 11.6709L7.13086 11.6775C6.99609 11.742 6.84375 11.6716 6.84375 11.5193C6.84375 11.1033 7.70508 10.2478 9 10.2478Z" fill="white"/></svg></div>
          불합격</div>`;
        }
        return '-';
      },
      sortData: (data: any) => {
        return data.types[0];
      },
    },
    {
      name: '직무 부서',
      id: '',
      sortable: true,
      data: (data: any) => this.client.getDept(data.dept, 'name'),
    },
    {
      name: '직무',
      id: '',
      sortable: true,
      data: (data: any) => this.client.getJob(data.job, 'name'),
    },
    {
      name: '면접 전형',
      id: '',
      sortable: true,
      data: (data: any) => this.client.getStep(data.step, 'name'),
      sortData: (data: any) => this.client.getStep(data.step, 'o'),
    },

    {
      name: '이메일',
      id: 'email',
      sortable: true,
    },
    {
      name: '휴대폰 번호',
      id: 'phone',
      sortable: true,
    },
    {
      name: '면접관',
      id: 'interviewersName',
      sortable: true,
      sortData: (data: any) => {
        return data.interviewersName === '-' ? '' : data.interviewersName;
      },
    },
    // {
    //   name: '',
    //   width: 36,
    //   data: (
    //     data: any,
    //   ) => `<svg class="cursor-pointer" width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path d="M2.85938 13.2207C1.5293 13.2207 0.802734 12.4941 0.802734 11.1523V2.39258C0.802734 1.05664 1.5293 0.324219 2.85938 0.324219H5.75391C6.62109 0.324219 7.0957 0.470703 7.66406 1.04492L10.4766 3.88672C11.0566 4.47266 11.1914 4.92969 11.1914 5.84375V11.1523C11.1914 12.4883 10.4648 13.2207 9.13477 13.2207H2.85938ZM3.04102 11.709H8.95312C9.45117 11.709 9.67969 11.457 9.67969 10.9883V6.20703H6.84375C5.83008 6.20703 5.34375 5.72656 5.34375 4.70703V1.83594H3.04102C2.54297 1.83594 2.31445 2.09375 2.31445 2.5625V10.9883C2.31445 11.457 2.54297 11.709 3.04102 11.709ZM6.95508 4.96484H9.5625L6.58594 1.95898V4.5957C6.58594 4.84766 6.70312 4.96484 6.95508 4.96484ZM6.62109 7.39062V8.62109L6.5625 9.45312L6.93164 9.06055L7.23633 8.73828C7.32422 8.62695 7.48828 8.54492 7.64062 8.54492C7.95117 8.54492 8.18555 8.76758 8.18555 9.06641C8.18555 9.23633 8.12695 9.36523 7.99219 9.48242L6.49219 10.8418C6.33984 10.9824 6.1875 11.0645 6 11.0645C5.80664 11.0645 5.6543 10.9824 5.50195 10.8418L4.00195 9.48242C3.86719 9.36523 3.80859 9.23633 3.80859 9.06641C3.80859 8.76758 4.04297 8.54492 4.35938 8.54492C4.50586 8.54492 4.66406 8.62695 4.75781 8.73828L5.06836 9.06055L5.43164 9.45312L5.37305 8.62109V7.39062C5.37305 7.04492 5.6543 6.81055 6 6.81055C6.33984 6.81055 6.62109 7.04492 6.62109 7.39062Z" fill="#0B0C0F"/>
    //   </svg>
    //   `,
    //   click: (data: any) => {
    //     this.downInterview(data);
    //   },
    // },
    // {
    //   name: '',
    //   width: 36,
    //   data: (
    //     data: any,
    //   ) => `<svg class="cursor-pointer" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path d="M10.248 2.8457L8.94141 1.53906L9.65039 0.841797C9.99023 0.501953 10.3945 0.472656 10.7168 0.794922L10.998 1.07617C11.3203 1.4043 11.3027 1.79688 10.957 2.14258L10.248 2.8457ZM2.68359 10.4043L1.1543 10.9844C0.9375 11.0664 0.697266 10.8379 0.785156 10.6035L1.38281 9.10352L8.30273 2.17188L9.60352 3.47852L2.68359 10.4043Z" fill="#0B0C0F"/>
    //   </svg>
    //   `,
    //   click: (data: any) => {
    //     this.editMode = true;
    //     this.editInterview = data.id;
    //   },
    // },
    // {
    //   name: '',
    //   width: 36,
    //   data: (
    //     data: any,
    //   ) => `<svg class="cursor-pointer" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path d="M6.29297 15.957C5.28516 15.957 4.67578 15.4062 4.62891 14.3984L4.24219 6.08984H3.69141C3.32227 6.08984 3.03516 5.82031 3.03516 5.44531C3.03516 5.07617 3.32227 4.80664 3.69141 4.80664H6.17578V3.95703C6.17578 2.97852 6.78516 2.42773 7.875 2.42773H10.1074C11.1973 2.42773 11.8125 2.97852 11.8125 3.95703V4.80664H14.2969C14.6719 4.80664 14.959 5.07617 14.959 5.44531C14.959 5.82031 14.6777 6.08984 14.2969 6.08984H13.752L13.3652 14.3984C13.3184 15.4004 12.7031 15.957 11.7012 15.957H6.29297ZM7.52344 4.00391V4.80664H10.4648V4.00391C10.4648 3.75195 10.2891 3.59961 10.0078 3.59961H7.97461C7.69922 3.59961 7.52344 3.75195 7.52344 4.00391ZM6.46875 14.6504H11.5195C11.8301 14.6504 12.0293 14.4395 12.041 14.0879L12.4102 6.08984H5.57227L5.94727 14.0879C5.96484 14.4336 6.16406 14.6504 6.46875 14.6504ZM7.2832 13.8008C7.01367 13.8008 6.82031 13.6191 6.81445 13.3496L6.64453 7.43164C6.63281 7.15625 6.81445 6.97461 7.10156 6.97461C7.37695 6.97461 7.57031 7.15039 7.57617 7.42578L7.75195 13.3438C7.75781 13.6133 7.57617 13.8008 7.2832 13.8008ZM8.99414 13.8008C8.71875 13.8008 8.52539 13.6191 8.52539 13.3496V7.41992C8.52539 7.15039 8.71875 6.97461 8.99414 6.97461C9.27539 6.97461 9.46875 7.15039 9.46875 7.41992V13.3496C9.46875 13.6191 9.27539 13.8008 8.99414 13.8008ZM10.7109 13.8008C10.418 13.8008 10.2363 13.6133 10.2422 13.3438L10.418 7.42578C10.4238 7.15039 10.6113 6.97461 10.8926 6.97461C11.1738 6.97461 11.3555 7.15625 11.3496 7.43164L11.1738 13.3496C11.168 13.625 10.9805 13.8008 10.7109 13.8008Z" fill="#0B0C0F"/>
    //   </svg>
    //   `,
    //   click: (data: any) => {
    //     this.deleteModalData.id = 'delete-one';
    //     this.deleteModalData.body = `선택한 지원자 ${data.name}의 정보를 삭제하시겠어요?`;
    //     this.deleteModalData.target = data;
    //     this.deleteModal = true;
    //   },
    // },
  ];

  options = {
    rowClick: (data: any) => {
      this.router.navigate(['interview', data.id], {
        // queryParams: {
        //   prev: 'search',
        //   data: this.search,
        // },
      });
      this.client.events.topbar.emit({
        id: 'clearSearch',
      });
    },
  };

  datas: any = [];

  deleteModal = false;
  deleteModalData: any = {
    id: 'delete',
    title: '지원자 삭제',
    titleIcon:
      '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.59375 20.3203C8.57031 20.3203 7.97656 19.9688 7.41406 19.3438L4.41406 15.9766C3.85156 15.3438 3.71094 14.9297 3.71094 14.0703V10.6484C3.71094 9.78906 3.85156 9.38281 4.41406 8.74219L7.41406 5.38281C7.97656 4.75781 8.57031 4.39844 9.59375 4.39844H14.4062C15.4297 4.39844 16.0312 4.75 16.5859 5.38281L19.5234 8.74219C20.1016 9.41406 20.2891 9.78906 20.2891 10.6484V14.0703C20.2891 14.9297 20.1406 15.3438 19.5781 15.9766L16.5859 19.3438C16.0234 19.9688 15.4297 20.3203 14.4062 20.3203H9.59375ZM12.0078 13.6328C12.4922 13.6328 12.7734 13.3594 12.7969 12.8516L12.9141 9.16406C12.9375 8.64062 12.5469 8.26562 12 8.26562C11.4453 8.26562 11.0625 8.63281 11.0859 9.15625L11.2031 12.8516C11.2266 13.3594 11.5078 13.6328 12.0078 13.6328ZM12.0078 16.3828C12.5703 16.3828 13.0234 16 13.0234 15.4453C13.0234 14.8984 12.5781 14.5078 12.0078 14.5078C11.4375 14.5078 10.9844 14.8984 10.9844 15.4453C10.9844 15.9922 11.4453 16.3828 12.0078 16.3828Z" fill="#FF3B30"/></svg>',
    body: '선택한 n명의 지원자 정보를 삭제하시겠어요?',
    desc: '삭제한 지원자의 면접도 함께 삭제돼요.',
    buttonCancel: '취소하기',
    buttonSubmit: '적용하기',
    target: null,
  };

  cb = {
    updateInterviews: async (data: any) => {
      await this.updateData();
    },
  };

  async ngOnInit() {
    console.log(this);

    this.socket.on('updateInterviews', this.cb.updateInterviews);
    this.socket.emit('joinRoom', 'client' + this.client.id);
  }

  async ngOnDestroy() {
    this.socket.removeListener('updateInterviews', this.cb.updateInterviews);
    //this.socket.emit('leaveRoom', 'client' + this.client.id);
  }

  async ngOnChanges() {
    console.log('search', this);
    console.log(this.search);

    await this.client.waitLoad();
    await this.updateData();
  }

  async updateData() {
    const res = await this.ajax.post('i/getInterviews', {
      token: this.client.token,
      search: this.search,
    });
    const datas = res.data.interviews;

    console.log(datas);

    this.datas = datas;

    let origIds: any = {};

    for (let i = datas.length - 1; i >= 0; i--) {
      let data = datas[i];
      if (typeof origIds[data.origId] === 'undefined') {
        origIds[data.origId] = 1;
      } else {
        console.log(i, data);
        datas.splice(i, 1);
      }
    }

    for (let data of this.datas) {
      data.checked = false;
      data.files = JSON.parse(data.files);
      data.interviewers = JSON.parse(data.interviewers);
      // data.rating = JSON.parse(data.rating);
      data.interviewersName = '-';
      if (data.interviewers.length > 0) {
        data.interviewersName = this.client.getInterviewer(
          data.interviewers[0],
          'name',
        );
        if (data.interviewers.length > 1) {
          data.interviewersName += ` 외 ${data.interviewers.length - 1}명`;
        }
      }

      data.types = [];
      if (data.status <= 0) {
        if (
          data.status === 0 &&
          (!data.timeSet || data.interviewers.length === 0)
        ) {
          data.types.push(0);
        } else {
          data.types.push(1);
        }
      } else if (data.status === 1 || data.status === 2) {
        data.types.push(2);
      } else if (data.status === 3) {
        data.types.push(3);
      } else if (data.status === 4) {
        data.types.push(4);
      } else if (data.status === 5) {
        data.types.push(5);
      }
    }
  }

  async onEvent(e: any) {
    console.log('event', e);
    if (e === 'close') {
      this.editMode = false;
      return;
    }
    if (e.name === 'edit') {
      this.editMode = false;
      if (e.value === 'save') {
        await this.ajax.socket(
          'client' + this.client.id,
          'updateInterviews',
          {},
        );
        //await this.updateData();
      }
    } else if (e.id === 'table') {
      if (e.data === 'delete') {
        console.log('delete');
        let count = 0;
        for (let data of this.datas) {
          if (data.checked) {
            count += 1;
          }
        }
        this.deleteModalData.id = 'delete';
        this.deleteModalData.body = `선택한 ${count}명의 지원자 정보를 삭제하시겠어요?`;
        this.deleteModal = true;
      }
    } else if (e.id === 'delete') {
      this.deleteModal = false;
      if (e.data === 'submit') {
        let datas = [];
        for (let data of this.datas) {
          if (data.checked) {
            datas.push(data);
          }
        }
        console.log(datas);
        for (let data of datas) {
          await this.client.deleteInterview(data.id);
        }
        await this.ajax.socket(
          'client' + this.client.id,
          'updateInterviews',
          {},
        );
        //await this.updateData();
        this.client.setToast({
          data: '지원자 정보와 관련 면접 카드가 삭제되었습니다.',
        });
      }
    } else if (e.id === 'delete-one') {
      this.deleteModal = false;
      if (e.data === 'submit') {
        await this.client.deleteInterview(this.deleteModalData.target.id);
        await this.ajax.socket(
          'client' + this.client.id,
          'updateInterviews',
          {},
        );
        //await this.updateData();
        this.client.setToast({
          data: '지원자 정보와 관련 면접 카드가 삭제되었습니다.',
        });
      }
    }
  }
}

<div class="flex h-full w-full gap-[4px]">
  <div
    class="flex w-[400px] min-w-0 flex-auto flex-col rounded-m bg-neutral100"
  >
    <div class="flex h-[56px] items-center">
      <div class="m-[16px] flex-auto select-none text-title-m text-neutral0">
        알림
      </div>
      <svg
        class="me-[16px] cursor-pointer"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        (click)="event.emit({ id: 'alert', data: 'close' })"
      >
        <path
          d="M5.54317 11.2929C5.15264 11.6834 5.15264 12.3166 5.54317 12.7071L10.3361 17.5C10.7265 17.8904 11.3596 17.8904 11.7501 17.5C12.1405 17.1095 12.1405 16.4764 11.7501 16.086L7.66406 12L11.7501 7.91397C12.1405 7.5235 12.1405 6.89043 11.7501 6.49997C11.3596 6.1095 10.7265 6.1095 10.3361 6.49997L5.54317 11.2929ZM11.1932 11.2929C10.8026 11.6834 10.8026 12.3166 11.1932 12.7071L15.9861 17.5C16.3765 17.8904 17.0096 17.8904 17.4001 17.5C17.7905 17.1095 17.7905 16.4764 17.4001 16.086L13.3141 12L17.4001 7.91397C17.7905 7.5235 17.7905 6.89043 17.4001 6.49997C17.0096 6.1095 16.3765 6.1095 15.9861 6.49997L11.1932 11.2929Z"
          fill="#98A1B3"
        />
      </svg>
    </div>
    <!-- <div class="mx-[16px] h-[1px] bg-neutral90"></div> -->
    <div class="mx-[16px] mt-[8px] flex gap-[4px]">
      <div
        class="relative flex h-[36px] cursor-pointer flex-col px-[12px] py-[6px]"
        *ngFor="let menu of topMenus"
        (click)="currentMenu = menu; processData()"
      >
        <div
          class="select-none text-label-l"
          [class.text-neutral0]="menu === currentMenu"
          [class.text-neutral40]="menu !== currentMenu"
        >
          {{ menu }}
        </div>
        <div
          class="absolute bottom-0 left-0 right-0 h-[2px] bg-neutral0"
          *ngIf="menu === currentMenu"
        ></div>
      </div>
    </div>
    <div class="mx-[16px] h-[1px] bg-neutral90"></div>
    <div
      class="scrollbar flex min-w-0 flex-auto flex-col gap-[12px] overflow-y-auto px-[16px] pb-[16px] pt-[12px]"
    >
      <div
        class="flex h-[36px] max-w-[400px] items-center"
        *ngIf="remainCount > 0"
      >
        <div class="flex-auto select-none text-body-m text-black">
          {{ remainCount }}개의 읽지 않은 알림이 있어요.
        </div>
        <div
          class="h-[28px] flex-shrink-0 cursor-pointer select-none rounded-s border border-neutral0 px-[16px] py-[4px] text-label-m text-neutral0"
          (click)="readAll()"
        >
          모두 읽음 처리
        </div>
      </div>
      <div
        class="flex h-[36px] max-w-[400px] items-center rounded-s bg-[#EBF9EE] px-[12px] py-[6px]"
        *ngIf="remainCount === 0"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.28906 19.2422C5.8125 19.2422 5.11719 18.5391 5.11719 17.0703V15.4141C5.11719 15.2734 5.07812 15.1719 4.97656 15.0625L3.80469 13.8906C2.76562 12.8516 2.75781 11.8672 3.80469 10.8281L4.97656 9.64844C5.07812 9.54688 5.11719 9.44531 5.11719 9.30469V7.64844C5.11719 6.16406 5.8125 5.47656 7.28906 5.47656H8.94531C9.08594 5.47656 9.1875 5.4375 9.28906 5.33594L10.4688 4.16406C11.5078 3.11719 12.4922 3.11719 13.5312 4.16406L14.7031 5.33594C14.8125 5.4375 14.9141 5.47656 15.0547 5.47656H16.7109C18.1797 5.47656 18.8828 6.17969 18.8828 7.64844V9.30469C18.8828 9.44531 18.9219 9.54688 19.0234 9.64844L20.1953 10.8281C21.2344 11.8672 21.2422 12.8516 20.1953 13.8906L19.0234 15.0625C18.9219 15.1719 18.8828 15.2734 18.8828 15.4141V17.0703C18.8828 18.5391 18.1797 19.2422 16.7109 19.2422H15.0547C14.9141 19.2422 14.8125 19.2734 14.7031 19.3828L13.5312 20.5547C12.4922 21.5938 11.5078 21.5938 10.4688 20.5547L9.28906 19.3828C9.1875 19.2734 9.08594 19.2422 8.94531 19.2422H7.28906ZM11.1328 16.2422C11.4453 16.2422 11.7188 16.0859 11.9062 15.7969L15.5547 10.1719C15.6719 9.98438 15.7656 9.78125 15.7656 9.59375C15.7656 9.15625 15.375 8.85156 14.9531 8.85156C14.6797 8.85156 14.4453 9 14.2578 9.29688L11.1094 14.3203L9.66406 12.5156C9.46094 12.2578 9.25781 12.1641 9 12.1641C8.57031 12.1641 8.21875 12.5078 8.21875 12.9453C8.21875 13.1562 8.28906 13.3438 8.44531 13.5391L10.3281 15.8125C10.5625 16.1016 10.8125 16.2422 11.1328 16.2422Z"
            fill="#34C759"
          />
        </svg>
        <div class="ms-[4px] select-none text-body-m text-green">
          모든 알림을 확인했습니다.
        </div>
      </div>
      <div
        class="flex max-w-[400px] select-none flex-col"
        *ngFor="let data of datas"
      >
        <div class="mb-[12px] flex h-[32px] items-center gap-[8px]">
          <div class="h-[24px] w-[24px]">
            <app-profile
              [path]="client.getInterviewer(data.data.interviewer, 'profile')"
              [name]="client.getInterviewer(data.data.interviewer, 'name')"
            ></app-profile>
          </div>
          <div class="flex flex-auto flex-col">
            <div class="text-label-m text-black">
              {{ client.getInterviewer(data.data.interviewer, "name") }}
            </div>
            <div class="text-body-s text-neutral40">
              {{ client.getInterviewer(data.data.interviewer, "dept") }}
            </div>
          </div>
          <!-- <div
            class="flex h-[28px] cursor-pointer select-none items-center gap-[4px] pe-[12px] ps-[6px]"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 14.8809C5.64844 14.8809 2.88867 12.1211 2.88867 8.76953C2.88867 5.42383 5.64844 2.6582 8.99414 2.6582C12.3457 2.6582 15.1113 5.42383 15.1113 8.76953C15.1113 12.1211 12.3516 14.8809 9 14.8809ZM9 13.5391C11.6426 13.5391 13.7695 11.4121 13.7695 8.76953C13.7695 6.12695 11.6426 4 8.99414 4C6.35156 4 4.23633 6.12695 4.23633 8.76953C4.23633 11.4121 6.35742 13.5391 9 13.5391ZM6.29297 8.76953C6.29297 8.42383 6.53906 8.17188 6.88477 8.17188H8.40234V6.6543C8.40234 6.30859 8.64844 6.0625 8.98828 6.0625C9.33984 6.0625 9.58594 6.30859 9.58594 6.6543V8.17188H11.1094C11.4551 8.17188 11.6953 8.42383 11.6953 8.76953C11.6953 9.10938 11.4551 9.35547 11.1094 9.35547H9.58594V10.8789C9.58594 11.2188 9.33984 11.4648 8.98828 11.4648C8.64844 11.4648 8.40234 11.2188 8.40234 10.8789V9.35547H6.88477C6.53906 9.35547 6.29297 9.10938 6.29297 8.76953Z"
                fill="#0B0C0F"
              />
            </svg>
            <div class="text-label-m text-neutral0">유사한 알림 비활성화</div>
          </div> -->
        </div>
        <div class="group flex flex-col">
          <div
            class="mb-[4px] rounded-b-l rounded-tr-l bg-neutral90 px-[16px] py-[12px] text-body-m group-hover:bg-neutral80"
            [class.text-neutral0]="data.isRead === 0"
            [class.text-neutral60]="data.isRead === 1"
            [class.cursor-pointer]="data.isRead === 0"
            (click)="read(data)"
          >
            {{ data.text }}
          </div>
          <div class="flex h-[28px] items-center">
            <div class="flex-auto text-body-s text-neutral40">
              {{ dayjs(data.created_at).format("YYYY년 MM월 DD일 A h시 mm분") }}
            </div>
            <div
              class="hidden h-[28px] flex-shrink-0 cursor-pointer select-none items-center gap-[4px] rounded-s border border-neutral0 py-[4px] pe-[6px] ps-[12px] text-label-m text-neutral0 group-hover:flex"
              (click)="moveInterview(data.data); read(data)"
              *ngIf="
                data.type === 'interview-create' ||
                data.type === 'interview-invite' ||
                data.type === 'interview-update'
              "
            >
              면접 정보 보러가기
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.70706 14.2072L6.29285 12.7929L9.58574 9.50005L6.29285 6.20715L7.70706 4.79294L12.4142 9.50005L7.70706 14.2072Z"
                  fill="#0B0C0F"
                />
              </svg>
            </div>
            <div
              class="hidden h-[28px] flex-shrink-0 cursor-pointer select-none items-center gap-[4px] rounded-s border border-neutral0 py-[4px] pe-[6px] ps-[12px] text-label-m text-neutral0 group-hover:flex"
              (click)="moveInterview(data.data); read(data)"
              *ngIf="data.type === 'interview-end'"
            >
              면접 결과 보러가기
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.70706 14.2072L6.29285 12.7929L9.58574 9.50005L6.29285 6.20715L7.70706 4.79294L12.4142 9.50005L7.70706 14.2072Z"
                  fill="#0B0C0F"
                />
              </svg>
            </div>
            <div
              class="hidden h-[28px] flex-shrink-0 cursor-pointer select-none items-center gap-[4px] rounded-s border border-neutral0 py-[4px] pe-[6px] ps-[12px] text-label-m text-neutral0 group-hover:flex"
              (click)="moveManage(); read(data)"
              *ngIf="
                data.type === 'interviewer-invite' ||
                data.type === 'interviewer-delete'
              "
            >
              관리자 콘솔 보러가기
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.70706 14.2072L6.29285 12.7929L9.58574 9.50005L6.29285 6.20715L7.70706 4.79294L12.4142 9.50005L7.70706 14.2072Z"
                  fill="#0B0C0F"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

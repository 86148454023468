import { Component, inject } from '@angular/core';
import { AjaxService } from '../../core/services/ajax.service';
import { ActivatedRoute } from '@angular/router';
import { ClientService } from '../../core/services/client.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-join-auth',
  templateUrl: './join-auth.component.html',
  styleUrl: './join-auth.component.scss',
})
export class JoinAuthComponent {
  email = '';

  flag = false;

  ajax = inject(AjaxService);
  route = inject(ActivatedRoute);
  client = inject(ClientService);
  sanitizer = inject(DomSanitizer);

  ngOnInit() {
    const email = this.route.snapshot.paramMap.get('email');
    if (email) {
      this.email = email;
    }
  }

  async sendMail() {
    if (!this.flag) {
      this.flag = true;
      const r = await this.ajax.post('auth/resendMail', {
        email: this.email,
      });

      if (r.data.error === 0) {
        this.client.setToast({
          data: '이메일이 전송되었습니다.',
        });
      } else {
        this.client.setToast({
          bg: 'red',
          data: '이메일 전송에 실패하였습니다.',
        });
      }
    }
  }
}

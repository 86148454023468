import { Component, Input, inject } from '@angular/core';
import { AjaxService } from '../../core/services/ajax.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent {
  @Input() path = '';
  @Input() name = '';
  @Input() border = false;
  ajax = inject(AjaxService);

  colors = [
    'blue',
    'green',
    'yellow',
    'orange',
    'red',
    'mint',
    'teal',
    'cyan',
    'indigo',
    'purple',
    'pink',
    'brown',
  ];

  hashStringToNumber(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = (hash << 5) + str.charCodeAt(i);
      hash = hash & hash; // Convert to 32bit integer
      hash = Math.abs(hash); // Make positive
    }
    return hash;
  }
}

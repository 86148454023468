import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
})
export class ModalComponent {
  @Input() data: any = {};
  @Output() event = new EventEmitter();

  id = '';
  title = '';
  titleIcon = '';
  body = '';
  desc = '';
  buttonCancel = '';
  buttonSubmit = '';

  sanitizer = inject(DomSanitizer);

  onKeyDown = (e: any) => {
    if (e.key === 'Escape') {
      this.event.emit({
        id: this.id,
        data: 'cancel',
      });
    }
  };

  ngOnInit() {
    let { id, title, titleIcon, body, buttonCancel, buttonSubmit, desc } =
      this.data;
    this.id = id;
    this.title = title;
    this.titleIcon = titleIcon;
    this.body = body;
    this.desc = desc;
    this.buttonCancel = buttonCancel;
    this.buttonSubmit = buttonSubmit;
    window.addEventListener('keydown', this.onKeyDown);
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.onKeyDown);
  }
}

<div
  class="flex h-screen w-screen items-center justify-center overflow-hidden bg-black"
>
  <div
    class="relative h-[720px] w-[1240px] bg-contain"
    [style.zoom]="zoom"
    *ngIf="currentPage !== ''"
    [style.backgroundImage]="
      'url(assets/imgs/tutorial/' + currentPage + '.png)'
    "
  >
    <div
      class="absolute cursor-pointer"
      *ngFor="let button of pages[currentPage].buttons"
      [style.width]="button[0] + 'px'"
      [style.height]="button[1] + 'px'"
      [style.top]="button[2] + 'px'"
      [style.left]="button[3] + 'px'"
      (click)="movePage(button[4])"
    ></div>
    <div
      class="absolute flex h-[48px] w-[102px] cursor-pointer items-center gap-[12px]"
      *ngIf="pages[currentPage].close"
      [style.top]="pages[currentPage].close[0] + 'px'"
      [style.left]="pages[currentPage].close[1] + 'px'"
      (click)="close()"
    >
      <!-- <div class="text-headline-s text-neutral100">닫기</div>
      <div
        class="flex h-[48px] w-[48px] items-center justify-center rounded-full shadow-s"
        style="background-color: rgba(255, 255, 255, 0.25)"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
            fill="white"
          />
        </svg>
      </div> -->
    </div>
  </div>
</div>

import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { AjaxService } from '../../core/services/ajax.service';
import { ClientService } from '../../core/services/client.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrl: './join.component.scss',
})
export class JoinComponent {
  @ViewChild('passwordInput') passwordInput!: ElementRef<HTMLInputElement>;

  ajax = inject(AjaxService);
  client = inject(ClientService);
  router = inject(Router);
  sanitizer = inject(DomSanitizer);

  username = '';
  password = '';

  step = 0;

  emailError = false;
  passwordError = true;

  async checkValidEmail() {
    const validateEmail = (email: string) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
    };

    if (!validateEmail(this.username)) {
      this.client.setToast({
        bg: 'red',
        data: '이메일이 유효하지 않습니다.',
      });
      return;
    }

    const r = await this.ajax.post('auth/checkEmail', {
      email: this.username,
    });

    console.log(r);
    if (r.data.error === 0) {
      this.client.setToast({
        bg: 'red',
        data: '이미 존재하는 이메일입니다.',
      });
      this.emailError = true;
      return false;
    }
    return true;
  }

  checkPassword() {
    const hasUpperCase = (str: string) => {
      return /[A-Z]/.test(str);
    };
    const hasLowerCase = (str: string) => {
      return /[a-z]/.test(str);
    };
    const hasNumber = (str: string) => {
      return /\d/.test(str);
    };
    const hasSpecialCharacter = (str: string) => {
      return /[!@#$%^&*(),.?":{}|<>]/.test(str);
    };

    let count = 0;
    if (hasUpperCase(this.password)) count += 1;
    if (hasLowerCase(this.password)) count += 1;
    if (hasNumber(this.password)) count += 1;
    if (hasSpecialCharacter(this.password)) count += 1;

    return count >= 2 && this.password.length >= 8;
  }

  async emailEnter() {
    if (this.step === 0) {
      this.step = 1;
      await this.checkValidEmail();
    }
    setTimeout(() => {
      this.passwordInput.nativeElement.focus();
    }, 0);
  }

  async submit() {
    if (this.step === 0) {
      this.step = 1;
      await this.checkValidEmail();
    } else if (this.step === 1) {
      if (!this.checkPassword()) {
        this.passwordError = true;
        this.client.setToast({
          bg: 'red',
          data: '비밀번호가 유효하지 않습니다.',
        });
        return;
      }
      if (!(await this.checkValidEmail())) {
        return;
      }

      const r = await this.ajax.post('auth/join', {
        email: this.username,
        password: this.password,
      });

      if (r.data.error === 0) {
        this.router.navigate(['email', this.username]);
      } else {
        this.client.setToast({
          bg: 'red',
          data: '회원가입에 실패하였습니다.',
        });
      }
    }
  }
}

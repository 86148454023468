import { Component, inject } from '@angular/core';
import { AjaxService } from '../../core/services/ajax.service';
import { ClientService } from '../../core/services/client.service';
import { UtilService } from '../../core/services/util.service';
import dayjs from 'dayjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent {
  ajax = inject(AjaxService);
  client = inject(ClientService);
  util = inject(UtilService);
  router = inject(Router);

  dragged = false;
  files: any = [];

  inputs: any = {
    name: '',
    email: '',
    phone: '',
    // dept: { name: '', id: -1 },
    // job: { name: '', id: -1 },
    dept: '',
    job: '',
    ino: '',
    step: 0,
    notice: '',
    files: [],
    interviewers: [],
    place: '',
    link: '',
    time1: '',
    time2: '',
    time: 30,
  };

  datas: any = {
    depts: [],
    jobs: [],
    interviewers: [],
  };

  modes: any = {
    dept: false,
    job: false,
  };

  modals: any = {
    dept: false,
    job: false,
  };

  tSelectEditText = '';
  tSelectEditAddText = '';

  lockDetail = false;

  leaveModalData: any = {
    id: 'leave-modal',
    title: '회원 탈퇴하기',
    titleIcon:
      '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.59375 20.3203C8.57031 20.3203 7.97656 19.9688 7.41406 19.3438L4.41406 15.9766C3.85156 15.3438 3.71094 14.9297 3.71094 14.0703V10.6484C3.71094 9.78906 3.85156 9.38281 4.41406 8.74219L7.41406 5.38281C7.97656 4.75781 8.57031 4.39844 9.59375 4.39844H14.4062C15.4297 4.39844 16.0312 4.75 16.5859 5.38281L19.5234 8.74219C20.1016 9.41406 20.2891 9.78906 20.2891 10.6484V14.0703C20.2891 14.9297 20.1406 15.3438 19.5781 15.9766L16.5859 19.3438C16.0234 19.9688 15.4297 20.3203 14.4062 20.3203H9.59375ZM12.0078 13.6328C12.4922 13.6328 12.7734 13.3594 12.7969 12.8516L12.9141 9.16406C12.9375 8.64062 12.5469 8.26562 12 8.26562C11.4453 8.26562 11.0625 8.63281 11.0859 9.15625L11.2031 12.8516C11.2266 13.3594 11.5078 13.6328 12.0078 13.6328ZM12.0078 16.3828C12.5703 16.3828 13.0234 16 13.0234 15.4453C13.0234 14.8984 12.5781 14.5078 12.0078 14.5078C11.4375 14.5078 10.9844 14.8984 10.9844 15.4453C10.9844 15.9922 11.4453 16.3828 12.0078 16.3828Z" fill="#FF3B30"/></svg>',
    body: '정말 회원 탈퇴하시겠어요?<br>개인정보가 사라지고 다시 접속할 수 없어요',
    desc: '',
    buttonCancel: '취소하기',
    buttonSubmit: '탈퇴하기',
    show: false,
  };

  async ngOnInit() {
    console.log(this);

    this.inputs.time1 = dayjs().format('YYYY-MM-DD');
    this.inputs.time2 = dayjs().format('HH:mm');

    this.datas.depts = JSON.parse(JSON.stringify(this.client.client.depts));
    this.datas.jobs = JSON.parse(JSON.stringify(this.client.client.jobs));
    this.datas.interviewers = JSON.parse(
      JSON.stringify(this.client.client.interviewers),
    );

    // if (this.datas.depts.length > 0) {
    //   this.inputs.dept = this.datas.depts[0];
    // }
    // if (this.datas.jobs.length > 0) {
    //   this.inputs.job = this.datas.jobs[0];
    // }

    for (let data of this.datas.depts) {
      data.edit = false;
    }
    for (let data of this.datas.jobs) {
      data.edit = false;
    }
    for (let data of this.datas.interviewers) {
      data.selected = false;
    }

    const r = await this.ajax.post('e/getProfile', {});
    const res = r.data.profile;

    this.inputs.dept = res.dept;
    this.inputs.job = res.job;

    this.inputs.name = res.name;
    this.inputs.email = res.email;
    this.inputs.phone = res.phone;
    for (let data of this.datas.depts) {
      if (data.id === res.dept) {
        this.inputs.dept = data;
        break;
      }
    }
    for (let data of this.datas.jobs) {
      if (data.id === res.job) {
        this.inputs.job = data;
        break;
      }
    }
    if (res.profile !== '') {
      this.files.push({
        path: res.profile,
        name: '아바타',
        size: 0,
      });
    }

    if (this.client.interviewer.firstLogin === 0) {
      this.lockDetail = true;
    }
    this.lockDetail = true;
    if (this.client.interviewer.level >= 3) {
      this.lockDetail = false;
    }
  }

  // async tSelectEditAddSubmit(target: any) {
  //   await this.ajax.post('query', {
  //     query: `INSERT INTO ${target + 's'}(client, name) VALUES (?, ?)`,
  //     data: [this.client.id, this.tSelectEditAddText],
  //   });
  //   this.tSelectEditAddText = '';

  //   if (target === 'job') {
  //     await this.client.updateJobs();
  //     this.datas.jobs = JSON.parse(JSON.stringify(this.client.client.jobs));
  //   } else if (target === 'dept') {
  //     await this.client.updateDepts();
  //     this.datas.depts = JSON.parse(JSON.stringify(this.client.client.depts));
  //   }

  //   this.inputs[target] = this.datas[target + 's'].find(
  //     (obj: any) => obj.id === this.inputs[target].id,
  //   );
  // }

  // async tSelectEditSubmit(target: any, data: any) {
  //   await this.ajax.query(`UPDATE ${target + 's'} SET name = ? WHERE id = ?`, [
  //     this.tSelectEditText,
  //     data.id,
  //   ]);

  //   if (target === 'job') {
  //     await this.client.updateJobs();
  //     this.datas.jobs = JSON.parse(JSON.stringify(this.client.client.jobs));
  //   } else if (target === 'dept') {
  //     await this.client.updateDepts();
  //     this.datas.depts = JSON.parse(JSON.stringify(this.client.client.depts));
  //   }

  //   this.inputs[target] = this.datas[target + 's'].find(
  //     (obj: any) => obj.id === this.inputs[target].id,
  //   );
  // }

  toggleEdit(datas: any, data: any) {
    if (data.edit) {
      data.edit = false;
    } else {
      for (let d of datas) {
        d.edit = false;
      }
      data.edit = true;
      this.tSelectEditText = data.name;
    }
  }

  async onDrop(e: any) {
    e.preventDefault();
    e.stopPropagation();
    this.dragged = false;

    if (e.dataTransfer.items) {
      [...e.dataTransfer.items].forEach(async (item, i) => {
        if (
          i === 0 &&
          item.kind === 'file' &&
          (item.type === 'image/png' || item.type === 'image/jpeg')
        ) {
          const file = item.getAsFile();
          if (
            (file.name.endsWith('.png') ||
              file.name.endsWith('.jpg') ||
              file.name.endsWith('.jpeg')) &&
            this.files.length < 1
          ) {
            await this.processFile(file);
          }
        }
      });
    } else {
      [...e.dataTransfer.files].forEach(async (file, i) => {
        if (
          i === 0 &&
          (file.name.endsWith('.png') ||
            file.name.endsWith('.jpg') ||
            file.name.endsWith('.jpeg')) &&
          this.files.length < 1
        ) {
          await this.processFile(file);
        }
      });
    }
  }

  async fileUpload(e: any) {
    for (let file of e.target.files) {
      if (
        (file.name.endsWith('.png') ||
          file.name.endsWith('.jpg') ||
          file.name.endsWith('.jpeg')) &&
        this.files.length < 3
      ) {
        await this.processFile(file);
      }
    }
    e.target.value = '';
  }

  async processFile(file: any) {
    const f: any = await this.ajax.uploadFile(file);
    this.files.push({
      path: f.path,
      name: file.name,
      size: file.size,
    });
  }

  async exit() {
    this.router.navigate(['interview']);
  }

  async submit() {
    let profile = this.client.interviewer.profile;
    if (this.files.length > 0) {
      profile = this.files[0].path;
    }
    if (profile !== '' && this.files.length === 0) {
      profile = '';
    }

    await this.ajax.post('e/updateProfile', {
      name: this.inputs.name,
      email: this.inputs.email,
      phone: this.inputs.phone,
      dept: this.inputs.dept,
      job: this.inputs.job,
      profile,
    });

    if (
      this.client.interviewer.level === 4 &&
      this.client.interviewer.firstLogin === 0
    ) {
      this.client.interviewer.firstLogin = 1;

      await this.ajax.post('client/initClient', {
        token: this.client.token,
      });
    }

    const res = await this.ajax.post('check-token', {
      token: this.client.token,
    });
    this.client.interviewer = res.data;
    await this.client.load();

    this.router.navigate(['interview']);

    this.client.setToast({
      data: '계정 정보를 저장했습니다.',
    });
  }

  async onEvent(e: any) {
    console.log(e);
    if (e.id === 'leave-modal') {
      this.leaveModalData.show = false;
      if (e.data === 'submit') {
        await this.leave();
      }
    }
  }

  async leave() {
    await this.ajax.post('interviewer/leave', {
      token: this.client.token,
    });

    location.href = 'https://findeet.io';
  }
}

import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AjaxService } from '../services/ajax.service';
import { ClientService } from '../services/client.service';

export const authGuard: CanActivateFn = async (route, state) => {
  const client = inject(ClientService);
  const ajax = inject(AjaxService);
  const router = inject(Router);
  if (client.token === '') {
    const token = localStorage.getItem('token');
    if (token) {
      client.token = token;
    } else {
      router.navigate(['']);
      return false;
    }
  }

  const res = await ajax.post('check-token', {
    token: client.token,
  });

  if (res.error === 1) {
    localStorage.removeItem('token');
    client.token = '';
    router.navigate(['']);
    return false;
  } else {
    if (client.interviewer.id === -1) {
      client.interviewer = res.data;
      client.interviewer.alerts = JSON.parse(client.interviewer.alerts);
      client.id = client.interviewer.client;
      await client.load();
      if (client.interviewer.firstLogin === 0) {
        router.navigate(['profile']);
      }
    }
    return true;
  }
};

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from '../components/modal/modal.component';
import { ProfileComponent } from '../components/profile/profile.component';
import { ModalInputComponent } from '../components/modal-input/modal-input.component';
import { FormsModule } from '@angular/forms';
import { SearchComponent } from '../pages/search/search.component';
import { TableComponent } from '../components/table/table.component';
import { AlertComponent } from '../pages/alert/alert.component';

@NgModule({
  declarations: [
    ModalComponent,
    ProfileComponent,
    ModalInputComponent,
    SearchComponent,
    TableComponent,
  ],
  imports: [CommonModule, FormsModule],
  exports: [
    ModalComponent,
    ProfileComponent,
    ModalInputComponent,
    SearchComponent,
    TableComponent,
  ],
})
export class SharedModule {}
